import axios from 'axios';

export async function fetchQuotation (id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/quotations/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchExternalQuotation (id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/external-quotation/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getReceipt (id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/transactions/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getInvoice (id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/invoices/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getExternalInvoice (id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/invoices/external-invoice/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
