/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DataGrid } from '../components';

import { fetchAllArtisans } from '../api';

function Index () {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState({});

  const navigate = useNavigate();

  const columns = [
    { field: 'name', headerName: "Artisan's Name", width: 350 },
    {
      field: 'email',
      headerName: "Artisan's Email",
      width: 350
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 200
    },
    {
      field: 'assessments',
      headerName: 'Assessed',
      description: 'date',
      width: 160
    },
    {
      field: 'vetted',
      headerName: 'Vetted',
      width: 160
    },
    {
      field: 'upskilled',
      headerName: 'Upskilled',
      width: 160
    }
  ];

  const getAllArtisans = async (page) => {
    setLoading(true);
    const response = await fetchAllArtisans({
      page: page + 1,
        ...(searchFilter?.type === 'email' ? { email: searchFilter.value } : {}),
        ...(searchFilter?.type === 'name' ? { name: searchFilter.value } : {}),
        ...(searchFilter?.type === 'phone' ? { phone: searchFilter.value } : {}),
      });
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((artisanData) => ({
        ...artisanData,
        name: `${artisanData?.first_name} ${artisanData?.last_name}`,
        assessments:
          artisanData?.competency_assessments?.length > 0 ? 'True' : 'False',
        vetted: artisanData?.vetting_report?.length > 0 ? 'True' : 'False',
        upskilled:
          artisanData?.upskilling_reports?.length > 0 ? 'True' : 'False'
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const getPage = async (page) => {
    await getAllArtisans(page);
    setPage(page);
  };

  const makeAssessments = (row) => {
    localStorage.setItem(`artisan_${row?.id}`, JSON.stringify(row?.row));
    navigate(`${row?.id}`);
  };

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      getAllArtisans(page);
    setSearchFilter({});
    }
    let categorize;
    if (searchTerm.includes('@')) {
      categorize = { type: 'email', value: searchTerm };
    } else if (/^\+?\d+$/.test(searchTerm)) {
      categorize = { type: 'phone', value: searchTerm };
    } else {
      categorize = { type: 'name', value: searchTerm };
    }
    setSearchFilter(categorize);
  };

  useEffect(() => {
    getAllArtisans(page);
  }, [page, searchFilter]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <Typography variant="h5" mb={3}>
        All Artisans Assessment

      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={3}>
          <Box display="flex" sx={{ flexGrow: 1, gap: 4, }}>
          <form
              style={{ display: 'flex', flexGrow: 1, gap: 4 }}
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
              }}
            >
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              placeholder='Search by name or email or phone number'
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '200px'
              }}
              onClick={() => handleSearch()}
            >
              Search
            </Button>
            </form>
          </Box>
        </Box>

      <Box width="100%">
        <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          onRowClick={makeAssessments}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[15]}
          paginationMode="server"
          getRowClassName={(params) =>
            params?.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </Box>
    </Box>
  );
}

export default Index;
