import axios from 'axios';

export async function fetchAllAdmins () {
  try {
    const response = await axios.get('/admin/all', {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function deleteAdmin (row) {
  const data = {
    name: row.name
  };
  try {
    const response = await axios.delete(`/admin/${row.id}`, {
      data
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function updateAdmin (id, data) {
  try {
    const response = await axios.patch(`/admin/${id}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function createAdmin (data) {
  try {
    const response = await axios.post('/admin/create', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function fetchAvailableRoles () {
  try {
    const response = await axios.get('/admin/roles', {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function fetchAllPermissions () {
  try {
    const response = await axios.get('/admin/permissions', {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function revokePermission (roleId) {
  try {
    const response = await axios.get('/admin/roles', {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function createRole (data) {
  try {
    const response = await axios.post('/admin/roles/create', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function updateRole (id, data) {
  try {
    const response = await axios.patch(`/admin/roles/${id}/permissions`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}
