import React, { useState, useEffect } from 'react';
import {
  Modal,
  Card,
  Typography,
  Box,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  p: 3,
  maxHeight: '90vh',
  overflow: 'auto'
};

const EditRoleModal = (props) => {
  const { open, onClose, role, permissions = [], onSubmit } = props;
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (role && role.permisions) {
      setSelectedPermissions(role.permisions.map(p => p.id));
    }
  }, [role]);

  const handlePermissionChange = (permissionId) => {
    setSelectedPermissions(prev => {
      if (prev.includes(permissionId)) {
        return prev.filter(id => id !== permissionId);
      } else {
        return [...prev, permissionId];
      }
    });
  };

  const handleSubmit = () => {
    onSubmit({
      id: role.id,
      permissions: selectedPermissions
    });
    onClose();
  };

  if (!role) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-role-modal"
    >
      <Card sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            Edit {role.name}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box mb={3}>
          <Typography variant="subtitle2" color="text.secondary" mb={2}>
            Select Permissions
          </Typography>
          <FormGroup>
            {permissions.map((permission) => (
              <FormControlLabel
                key={permission.id}
                control={
                  <Checkbox
                    checked={selectedPermissions.includes(permission.id)}
                    onChange={() => handlePermissionChange(permission.id)}
                  />
                }
                label={permission.name}
              />
            ))}
          </FormGroup>
        </Box>

        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

EditRoleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  role: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    permisions: PropTypes.array.isRequired,
  }).isRequired
};

EditRoleModal.defaultProps = {
  permisions: []
};

export default EditRoleModal;
