/* eslint-disable react/prop-types */
import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { fetchOneArtisan, fetchOneCustomer } from '../../api';
import Loader from '../Loader';
import moment from 'moment';

function QuotationDetail(props) {
  const { quotation } = props;
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [artisan, setArtisan] = useState([]);

  const isExternal = quotation?.reference?.startsWith('EXT-');

  const checkCustomer = useCallback(
    async (id) => {
      setLoading(true);
      const response = await fetchOneCustomer(id);
      setLoading(false);

      if (response?.success && response?.data) {
        setCustomer(response?.data);
      }
    },
    [quotation]
  );

  const checkArtisan = useCallback(
    async (id) => {
      setLoading(true);
      const response = await fetchOneArtisan(id);
      setLoading(false);

      if (response?.success && response?.data) {
        setArtisan(response?.data);
      }
    },
    [quotation]
  );

  useEffect(() => {
    if (quotation?.customer_id) {
      checkCustomer(quotation?.customer_id);
    }
    if (quotation?.artisan_id) {
      checkArtisan(quotation?.artisan_id);
    }
  }, [quotation]);

  const formatNumber = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? 0 : number;
  };

  const calculateTotal = () => {
    if (!quotation?.items?.length) return 0;

    return quotation.items.reduce((sum, item) => {
      const itemTotal = isExternal
        ? formatNumber(item.total_price)
        : formatNumber(item.price) * formatNumber(item.quantity);
      return sum + itemTotal;
    }, 0);
  };

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box alignItems="center" justifyContent="center" display="flex">
            <Typography
              id="modal-modal-title"
              variant="h5"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              {isExternal ? 'External' : 'Internal'} Quotation Detail
            </Typography>
          </Box>

          <Grid container>
            <Grid item lg={8} sm={12}>
              <Typography variant="h6">
                Status:{' '}
                <Typography
                  component="span"
                  color={quotation?.status === 'accepted'
                    ? 'success.main'
                    : 'primary'
                  }
                >
                  {quotation?.status?.charAt(0).toUpperCase() + quotation?.status?.slice(1)}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Grid lg={12}>
            <LinearProgress
              sx={{ borderRadius: 5, height: 2 }}
              value={100}
              variant="determinate"
            />
          </Grid>

          <Grid container mt={1} spacing={2}>
            <Grid item lg={6} md={6} sm={6}>
              <Typography color="primary">
                Customer:
                <Typography component="span" color="text.secondary" pl={1}>
                  {isExternal
                    ? quotation?.customer_name
                    : `${customer?.first_name || ''} ${customer?.last_name || ''}`}
                </Typography>
              </Typography>
            </Grid>

            {isExternal && (
              <>
                <Grid item lg={6} md={6} sm={6}>
                  <Typography color="primary">
                    Customer Email:
                    <Typography component="span" color="text.secondary" pl={1}>
                      {quotation?.customer_email}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                  <Typography color="primary">
                    Customer Phone:
                    <Typography component="span" color="text.secondary" pl={1}>
                      {quotation?.customer_phone}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item lg={6} md={6} sm={6}>
              <Typography color="primary">
                Artisan:
                <Typography component="span" color="text.secondary" pl={1}>
                  {`${artisan?.first_name || ''} ${artisan?.last_name || ''}`}
                </Typography>
              </Typography>
            </Grid>

            {isExternal && (
              <>
                <Grid item lg={6} md={6} sm={6}>
                  <Typography color="primary">
                    Job Title:
                    <Typography component="span" color="text.secondary" pl={1}>
                      {quotation?.job_title}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                  <Typography color="primary">
                    Job Description:
                    <Typography component="span" color="text.secondary" pl={1}>
                      {quotation?.job_description}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item lg={6} md={6} sm={6}>
              <Typography color="primary">
                Reference:
                <Typography component="span" color="text.secondary" pl={1}>
                  {quotation?.reference}
                </Typography>
              </Typography>
            </Grid>

            <Grid item lg={6} md={6} sm={6}>
              <Typography color="primary">
                Created:
                <Typography component="span" color="text.secondary" pl={1}>
                  {moment(quotation?.created_at).format('ll')}
                </Typography>
              </Typography>
            </Grid>

            <Grid item lg={6} md={6} sm={6}>
              <Typography color="primary">
                Updated:
                <Typography component="span" color="text.secondary" pl={1}>
                  {moment(quotation?.updated_at).format('ll')}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Box mt={4}>
            <Typography variant="h6" color="primary" mb={2}>
              Items
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Unit</TableCell>
                    <TableCell align="right">Unit Price</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotation?.items?.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.item}</TableCell>
                      <TableCell align="right">{formatNumber(item.quantity).toLocaleString('en-US')}</TableCell>
                      <TableCell align="right">{item.unit}</TableCell>
                      <TableCell align="right">
                        {formatNumber(isExternal ? item.unit_price : item.price)
                          .toLocaleString('en-US', { minimumFractionDigits: 2 })}
                      </TableCell>
                      <TableCell align="right">
                        {(isExternal
                          ? formatNumber(item.total_price)
                          : formatNumber(item.price) * formatNumber(item.quantity))
                          .toLocaleString('en-US', { minimumFractionDigits: 2 })}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={4} align="right">
                      <Typography variant="subtitle1" fontWeight="bold">
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle1" fontWeight="bold">
                        {calculateTotal().toLocaleString('en-US', { minimumFractionDigits: 2 })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {isExternal && quotation?.invoice && (
            <Box mt={3}>
              <Typography variant="h6" color="primary" mb={1}>
                Invoice Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6}>
                  <Typography color="primary">
                    Invoice Reference:
                    <Typography component="span" color="text.secondary" pl={1}>
                      {quotation?.invoice?.reference}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                  <Typography color="primary">
                    Invoice Status:
                    <Typography component="span" color="text.secondary" pl={1}>
                      {quotation?.invoice?.status}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default QuotationDetail;
