import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Modal,
  Grid,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Chip
} from '@mui/material';
import moment from 'moment';

import { fetchAllInvoices, fetchAllExternalInvoices, getExternalInvoice, getInvoice, exportAdminData } from '../api';
import { DataGrid } from '../components';
import useMediaQuery from '@mui/material/useMediaQuery';

const TableSection = ({ title, children }) => (
  <Grid item xs={12}>
    <Typography variant="h6" color="primary" mt={2} mb={2}>
      {title}
    </Typography>
    <TableContainer component={Paper}>
      {children}
    </TableContainer>
  </Grid>
);

TableSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

function InvoiceDetails({ invoice, isExternal }) {
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null);

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      if (!invoice?.id) return;
      setLoading(true);
      try {
        const response = await (isExternal ? getExternalInvoice(invoice.id) : getInvoice(invoice.id));
        if (response?.success && response?.data) {
          setInvoiceDetails(response.data);
        }
      } catch (error) {
        console.error('Error fetching invoice details:', error);
      }
      setLoading(false);
    };

    fetchInvoiceDetails();
  }, [invoice?.id, isExternal]);

  if (!invoice || loading) {
    return (
      <Box sx={{ width: '100%', p: 3 }}>
        <LinearProgress />
      </Box>
    );
  }

  if (!invoiceDetails) {
    return (
      <Box sx={{ width: '100%', p: 3 }}>
        <Typography color="error">Failed to load invoice details</Typography>
      </Box>
    );
  }

  const formatNumber = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? 0 : number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const calculateTotal = (items) => {
    if (!items?.length) return 0;
    return items.reduce((sum, item) => {
      const itemTotal = isExternal
        ? parseFloat(item.total_price || 0)
        : parseFloat(item.price || 0) * parseFloat(item.quantity || 0);
      return sum + itemTotal;
    }, 0);
  };

  return (
    <Box>
      <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
        <Typography variant="h5" fontWeight="bold" color="primary">
          Invoice Details
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Chip
            label={invoiceDetails.status}
            color={invoiceDetails.status === 'PAID' ? 'success' : 'warning'}
          />
        </Grid>

        <Grid item xs={12}>
          <LinearProgress
            sx={{ borderRadius: 5, height: 2 }}
            value={100}
            variant="determinate"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography color="primary">
            Reference:
            <Typography component="span" color="text.secondary" ml={1}>
              {invoiceDetails.reference}
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography color="primary">
            Customer:
            <Typography component="span" color="text.secondary" ml={1}>
              {isExternal
                ? invoiceDetails.quotation?.customer_name
                : `${invoiceDetails.customer?.first_name || ''} ${invoiceDetails.customer?.last_name || ''}`}
            </Typography>
          </Typography>
        </Grid>

        {isExternal && (
          <>
            <Grid item xs={6}>
              <Typography color="primary">
                Customer Email:
                <Typography component="span" color="text.secondary" ml={1}>
                  {invoiceDetails.quotation?.customer_email}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary">
                Customer Phone:
                <Typography component="span" color="text.secondary" ml={1}>
                  {invoiceDetails.quotation?.customer_phone}
                </Typography>
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <Typography color="primary">
            Created:
            <Typography component="span" color="text.secondary" ml={1}>
              {moment(invoiceDetails.created_at).format('ll')}
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography color="primary">
            Updated:
            <Typography component="span" color="text.secondary" ml={1}>
              {moment(invoiceDetails.updated_at).format('ll')}
            </Typography>
          </Typography>
        </Grid>

        {/* Items Table */}
        {(invoiceDetails.items?.length > 0 || invoiceDetails.quotation?.items?.length > 0) && (
          <TableSection title="Items">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Unit</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(invoiceDetails.items || invoiceDetails.quotation?.items || []).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.item}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">{item.unit}</TableCell>
                    <TableCell align="right">
                      {formatNumber(isExternal ? item.unit_price : item.price)}
                    </TableCell>
                    <TableCell align="right">
                      {formatNumber(
                        isExternal
                          ? item.total_price
                          : item.price * item.quantity
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    <Typography variant="subtitle1" fontWeight="bold">
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1" fontWeight="bold">
                      {formatNumber(calculateTotal(invoiceDetails.items || invoiceDetails.quotation?.items))}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableSection>
        )}

        {/* Transactions Table */}
        {invoiceDetails.transaction?.length > 0 && (
          <TableSection title="Transactions">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Reference</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Settled Amount</TableCell>
                  <TableCell align="right">Split Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceDetails.transaction.map((transaction) => (
                  <TableRow key={transaction.id}>
                    <TableCell>{transaction.reference}</TableCell>
                    <TableCell>
                      <Chip
                        label={transaction.transaction_type}
                        color="primary"
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="right">{formatNumber(transaction.amount)}</TableCell>
                    <TableCell align="right">{formatNumber(transaction.settled_amount)}</TableCell>
                    <TableCell align="right">{formatNumber(transaction.split_amount)}</TableCell>
                    <TableCell>
                      <Chip
                        label={transaction.status}
                        color={transaction.status === 'SUCCESSFUL' ? 'success' : 'warning'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{moment(transaction.created_at).format('ll')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableSection>
        )}
      </Grid>
    </Box>
  );
}

InvoiceDetails.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  isExternal: PropTypes.bool.isRequired
};

InvoiceDetails.defaultProps = {
  invoice: null
};

function AllInvoices() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [openInvoiceDetails, setOpenInvoiceDetails] = useState(false);

  const handleCloseInvoiceDetails = () => {
    setOpenInvoiceDetails(false);
    setSelectedInvoice(null);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(0);
    getInvoices(0, newValue);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    overflowY: 'scroll',
    maxHeight: '90vh',
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left'
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'scroll',
    maxHeight: '90vh',
    width: '90vw',
    minHeight: '30vh',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2
  };

  const columns = [
    {
      field: 'reference',
      headerName: 'Reference',
      width: 200,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.value}
        </Typography>
      )
    },
    {
      field: 'customer',
      headerName: 'Customer',
      width: 200,
      valueGetter: (params) => {
        if (activeTab === 0) {
          return `${params.row.customer?.first_name || ''} ${params.row.customer?.last_name || ''}`;
        }
        return params.row.quotation?.customer_name || 'N/A';
      }
    },
    {
      field: 'artisan',
      headerName: 'Artisan',
      width: 200,
      valueGetter: (params) =>
        `${params.row.artisan?.first_name || ''} ${params.row.artisan?.last_name || ''}`
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === 'PAID' ? 'success' : 'warning'}
          size="small"
        />
      )
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      width: 160,
      valueGetter: (params) => moment(params.value).format('ll')
    }
  ];

  const getInvoices = async (page, tab = activeTab) => {
    setLoading(true);
    const fetchFunction = tab === 0 ? fetchAllInvoices : fetchAllExternalInvoices;
    const response = await fetchFunction(page + 1);
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      setRowData(data?.data);
      setRowCount(data?.total);
    }
  };

  const getPage = async (page) => {
    await getInvoices(page);
    setPage(page);
  };

  const handleExport = async () => {
    setLoading(true);
    const type = activeTab === 0 ? 'invoices' : 'invoices/external-invoice';
    const response = await exportAdminData(type);
    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');
    a.download = `${type}_export_${date}.csv`;
    a.href = response?.data?.report;
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setLoading(false);
  };

  const handleOpenInvoiceDetails = (row) => {
    setSelectedInvoice(row);
    setOpenInvoiceDetails(true);
  };

  useEffect(() => {
    getInvoices(page);
  }, [page, activeTab]);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <Grid container>
        <Grid item lg={6}>
          <Typography variant="h5" mb={3}>
            All Invoices
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <Button
            variant="outlined"
            sx={{ width: 300, float: 'right' }}
            onClick={handleExport}
            >
            Export { activeTab === 0 ? 'Internal' : 'External' } Invoices
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Internal Invoices" />
          <Tab label="External Invoices" />
        </Tabs>
      </Box>

      <Box width="100%">
        <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          onRowClick={(row) => handleOpenInvoiceDetails(row)}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[15]}
          paginationMode="server"
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />

        <Modal
          open={openInvoiceDetails}
          onClose={handleCloseInvoiceDetails}
          aria-labelledby="invoice-details-modal"
        >
          <Box sx={requiredWidth ? style : mobileStyle}>
            <InvoiceDetails
              invoice={selectedInvoice}
              isExternal={activeTab === 1}
            />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default AllInvoices;
